import React from "react"
import {graphql} from "gatsby";
import PageGabarit from "./modèles/page-texte";
import styled from "styled-components";

const StyledPageGabarit = styled(PageGabarit)`
  main {
  
    & > div {
      max-width: 100%;
    }
    
    p {
      text-align: center;
      font-size: 16px;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    h2 {
      text-align: center;
      margin-top: 100px;
    }

    nav {
      margin-top:  80px;

      a {
        display: block;
        text-decoration: none;
        border: none;
        padding: 0;
        
        h2 {        
            margin: 1em auto;
        }

        &:hover {
          background: none;          
          
          h2 {
            text-decoration: underline;
          }
        }        
      }
    }
  }  
`


export const pageQuery = graphql`
    query($id: String!) {
        page: mdx(id: {eq: $id}) {
            ...PageContent
        }
    }
`

const NotFoundGabarit = (props) => <StyledPageGabarit {...props}/>

export default NotFoundGabarit
